.main__layout {
  margin-left: 240px;
  height: 100%;
  flex: 1;
}

@media (max-width: 1025px) {
  .main__layout {
    margin-left: 0px;
    flex: 1;
  }
}
@media (max-width: 625px) {
  .main__layout {
    margin-left: 0px;
    flex: 1;
  }
}
.content {
  flex: 1;
  padding: 15px;
  height: 100%;
}

@media (max-width: 625px) {
  .content {
    flex: 1;
    padding: 5px;
  }
}

.wrapper {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}