@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900&display=swap");


.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f5f5f5; 
}
.login-box {
  padding: 20px;
  background-color: #ffffff;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  border-radius: 8px;
  text-align: center;
  width: 100%;
  margin: 0 auto;
  box-sizing: border-box;
  max-width: 450px;
}



@media only screen and (max-width: 768px) {
  .login-body {
    background-size: auto;
    overflow: hidden;
    width: 100%;
  }
}

.logos {
  width: 240px;
  margin-bottom: 20px;
}

.reglable {
  font-family: "Poppins", sans-serif;
  text-align: start;
  align-items: start;
  color: #3a3a3a;
}

.reg-form {
  width: 100%;
  max-width: 100%;
  width: 100%;
  margin-bottom: 2px;
  padding: 10px;
  border: 2px solid #ffffff;
  color: #ffffff;
  border-radius: 8px;
  background-color: rgba(27, 27, 27, 0.511);
  font-family: "Poppins", sans-serif;
}

.reg-form:focus {
  outline: 0;
  box-shadow: none !important;
}

.login-button {
  background-color: #ffae00;
  color: white;
  padding: 10px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  width: 100%;
  font-family: "Poppins", sans-serif;
}

.forgot-password-link {
  display: block;
  margin-top: 15px;
  color: #1d1d1d;
  text-decoration: none;
  font-family: "Poppins", sans-serif;
}

.forgot-password-link:hover {
  text-decoration: underline;
}

.eyepassword {
  color:#ffff;
  font-size: 16px;
  cursor: pointer;
}

.password-input-container {
  position: relative;
}

.password-toggle {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
}

.custom-inputs::placeholder {
  color: #ffff;
  font-family: "Poppins", sans-serif;
}