@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@200..1000&display=swap');

.Teams {
  margin-top: 60px;
  padding: 10px;
  padding-bottom: 10px;
  height: calc(100% - 60px);
}

.inboxhead{
  font-family: "Nunito Sans", sans-serif;
  font-size: 29px;
  font-weight: 600;
  margin: 0px 0px 0px 0px !important;
}
.linkdecoration{
  text-decoration: none;
}
.email-list{
  background-color: #ffff;
  border: 1px solid #b9b9b94a;
  padding: 20px;
  border-radius: 20px;
 
  font-family: "Nunito Sans", sans-serif;
}

.detailsswitch{
  background-color: #ffff;
  padding: 10px;
  border-radius: 15px;
}
.paraswitch1{
  padding: 7px;
  cursor: pointer;
  font-size: 14px;
  margin: 0px 0px 0px 0px !important;
  color: #525251;
  font-family: "Nunito Sans", sans-serif;

}
.paraswitch1:hover{
  background-color: hsla(43, 100%, 63%, 0.31);
  color: #FFC42B;
  border-radius: 3px;

  padding: 7px;
  cursor: pointer;
  font-size: 14px;
  font-family: "Nunito Sans", sans-serif;
}
.paraswitch1:active
.paraswitch1:focus{
  background-color: #ffc32b5a;
  color: #FFC42B;
  border-radius: 3px;

  padding: 7px;
  cursor: pointer;
  font-size: 14px;
  font-family: "Nunito Sans", sans-serif;
}



.form-check-input:checked {
  background-color: #949da9 !important;
  border-color: #1b1c1c !important;
}
.checboxt{
  margin-left: 20px !important;
}
.search-bar-input {
  width: 90%;
  padding: 5px;
  border: none;
  border-radius: 3px;
  margin-left: 8px;
  background-color: transparent;
  font-family: "Nunito Sans", sans-serif;
}
.search-bar-input:focus {
  width: 90%;
  padding: 5px;
  border: none;
  outline: none;
  border-radius: 3px;
  margin-left: 8px;
  font-family: "Nunito Sans", sans-serif;
}



.search-bar {
  display: flex;
  width: 50%;
    align-items: center;
    padding: 2px;
    border: 1px solid #ddd;
    border-radius: 30px;
    background-color:#e4e4e4;
    margin-left: 20px;
}

@media  screen and (max-width: 767px) {
  .search-bar-input {
    width: 90%;
    padding: 5px;
    border: none;
    border-radius: 3px;
    margin-left: 8px;
    background-color: transparent;
  }
  .search-bar {
    display: flex;
    width: 90%;
      align-items: center;
      padding: 2px;
      border: 1px solid #ddd;
      border-radius: 30px;
      background-color:#e4e4e4;
      margin-left: 20px;
  }
}

.search-icon {
  margin-left: 10px;
  color: #504f4f;
}

.delete-icon {
  cursor: pointer;
  color: rgb(67, 66, 66);
}
.start-icon{
  color: rgb(255, 183, 0);
  margin-left: 25px;
}
.arroright-icon{
  margin-left: 15px;
  font-size: 14px;
  cursor: pointer;
  font-weight: 700;

}
.arroright-icon:hover{
  color: rgb(119, 118, 118);
  font-weight: 800;
}
.arroright-icon:focus{
  color: rgb(119, 118, 118);
  font-weight: 800;
}
.arrorleft-icon{
  margin-left: 30px;
  font-size: 14px;
  cursor: pointer;
  font-weight: 700;
}

.arrorleft-icon:hover{
  color: rgb(119, 118, 118);
  font-weight: 800;
}
.arrorleft-icon:focus{
  color: rgb(119, 118, 118);
  font-weight: 800;
}

.status{
  padding:4px;
  border-radius: 30px;
  color: #00B69B;
  font-weight: 700;
  font-family: "Nunito Sans", sans-serif;
  font-size: 14px;
  margin: 0px 0px 0px 0px !important;

}
.spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  width: 36px;
  height: 36px;
  border-radius: 50%;
  border-left-color: #09f;
  animation: spin 1s linear infinite;
  margin: auto;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.disabled {
  pointer-events: none;
  opacity: 0.5;
}

.clas-certificate {
  padding: 3px 20px;
  background-color: #FFC42B;
  color: #111111;
  border: none;
  cursor: pointer;
  font-weight: 600;
  transition: background-color 0.3s;
}

.clas-certificate:disabled {
  background-color: #ccc;
  color: #666;
  cursor: not-allowed;
}

.common-overall-container{
  background-color: #ffff;
  border-radius: 10px;
  box-shadow: rgba(154, 154, 212, 0.25) 0px 2px 5px -1px, rgba(92, 88, 88, 0.3) 0px 1px 3px -1px;
  overflow: auto;
  max-height: calc(100vh - 194px);
  margin-bottom: 5px;
}
.tbody-responsive-container{
 height: calc(100% - 0px);
 background-color: #ffff !important;
 overflow-y: auto;
}
.table {
  font-size: 16px;
  border: none !important;
  border-collapse:collapse;
  background-color: transparent ;
  border-spacing: 0px 0px  !important;
  margin-bottom: 0.5rem !important;
}
 tr ,th,td{
  border: none !important;
}

.table td{
  text-align: center;
  vertical-align: middle;
  white-space: nowrap; 
  color: #303030 !important;
  font-size: 16px;
  font-weight: 500 !important;

}
.table th {
  background-color: #FFC42B !important;
  color: #303030c9 !important;
  padding: 12px !important;
  font-size: 15px; 
  text-align: center;
  position: sticky !important;
  top: 0;
}

.table thead {
position: sticky !important;
top: 0;
z-index: 0;
max-width: fit-content !important;
overflow: clip;
}
.tbody-responsive tr {
width: 100%;
table-layout: fixed;
}








