@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900&display=swap");


.backlogin{
    color: #e98718;
    font-weight: 600;
    font-size: 16px;
    font-family: "Poppins", sans-serif;
}
.logintext-link{
    color: #232323;
    margin-left: 10px;
    text-decoration: none;
    font-family: "Poppins", sans-serif;
    font-weight: 500;
}
.logintext-link:hover {
    text-decoration: underline;
  }
  .Tryanother{
    color: #FFFFFF;
    font-weight: 600;
    display: block;
    margin-top: 15px;
    text-decoration: none;
    font-size: 15px;
    font-family: "Poppins", sans-serif;
  }
  .Tryanother:hover {
    text-decoration: underline;
  }
  .backsetpassword{
    color: #e98718;
    font-weight: 550;
    margin-top: 20px;
    font-size: 16px;
    font-family: "Poppins", sans-serif;
  }

  .otp-inputs {
    display: flex;
    justify-content: center;
    gap: 10px; 
  }
  
  .otp-field {
    width: 50px; 
    height: 50px; 
    text-align: center;
    font-size: 20px;
    color: #FFFFFF;
    border: 1px solid #ffffff;
    border-radius: 5px;
    background-color: rgba(27, 27, 27, 0.511);
  }
  
  .otp-field:focus {
    border-color: #ff9800;
    outline: none;
  }
  
  @media (max-width: 600px) {
    .otp-field {
      width: 45px; 
      height: 45px; 
      font-size: 16px; 
    }
  
    .otp-inputs {
      gap: 7px; 
    }
  }
  
  .sendbox{
    padding: 0px 20px 0px 20px !important;
    border-radius: 8px;
    text-align: center;
    max-width: 400px;
    width: 100%;
  }
