@import url("https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@200..1000&display=swap");
.dashboard {
  margin-top: 40px;
  padding: 20px;
  padding-bottom: 40px;
}
@media (max-width: 625px) {
  .dashboard {
    margin-top: 60px;
    padding: 10px;
    padding-bottom: 40px;
  }
}
body {
  background-color: #f0f0f0;
}

.dashstart {
  color: #202224;
  font-family: "Nunito Sans", sans-serif;
  font-weight: 600;
  font-size: 28px;
  margin: 0px 0px 0px 0px !important;
}

.card-boc1 {
  background-color: #ffff;
  box-shadow: rgba(203, 203, 203, 0.35) 0px 8px 17px;
  border: none;
  outline: 0;
  color: #202224;
  padding: 8px;
  padding-right: 30px;
  border-radius: 15px;
}

.card-head {
  font-family: "Nunito Sans", sans-serif;
  font-size: 17px;
}

.count-dash {
  font-size: 25px;
  font-weight: 700;
  font-family: "Nunito Sans", sans-serif;
  margin-left: 20px;
}
.wcards {
  width: 50px;
}

.Revenue-Graph {
  margin-top: 50px;
}
.graphline {
  background-color: #ffff;
  padding: 10px;
  border-radius: 10px;
}
.graphead {
  font-size: 22px;
  font-family: "Nunito Sans", sans-serif;
  color: #202224;
  font-weight: 700;
}
.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 150px;
}
.userindicate{
    color: #00261C;
    font-family: "Nunito Sans", sans-serif;
    font-weight: 700;
}
.card-active{
    background-color: #00DEA333;
    box-shadow: rgba(203, 203, 203, 0.35) 0px 8px 17px;
    border: none;
    outline: 0;
    color: #202224;
    border-radius: 10px;
}
.card-inactive{
    background-color: #FFEEC1;
    box-shadow: rgba(203, 203, 203, 0.35) 0px 8px 17px;
    border: none;
    outline: 0;
    color: #202224;
    border-radius: 10px;
}

.card-scerio{
    background-color: #FFFFFF7D;
    border: 1px solid #F3F3F3;
    outline: 0;
    color: #333333;
    padding: 8px 28px;
    border-radius: 10px;
    box-shadow: rgba(203, 203, 203, 0.35) 0px 8px 17px;
}
.card-legal{
    background-color: #FFFFFF7D;
    border: 1px solid #F3F3F3;
    outline: 0;
    color: #333333;
    box-shadow: rgba(203, 203, 203, 0.35) 0px 8px 17px;
    padding: 8px 28px;
    border-radius: 10px;
}
.card-cross{
    background-color: #FFFFFF7D;
    border: 1px solid #F3F3F3;
    outline: 0;
    color: #333333;
    padding: 8px 28px;
    box-shadow: rgba(203, 203, 203, 0.35) 0px 8px 17px;
    border-radius: 10px;
}
.card-judgement{
    background-color: #FFFFFF7D;
    border: 1px solid #F3F3F3;
    outline: 0;
    padding: 8px 28px;
    border-radius: 10px;
    box-shadow: rgba(203, 203, 203, 0.35) 0px 8px 17px;
}