.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.4);
    z-index: 1000;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .modal-box {
    background: #fff;
    padding: 15px 15px;
    border-radius: 10px;
    width: 320px;
    text-align: center;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
  }
  
  .modal-box h3 {
    font-size: 20px;
    margin-bottom: 10px;
    color: #333;
  }
  
  .modal-box p {
    font-size: 16px;
    color: #555;
  }
  
  .modal-actions {
    margin-top: 20px;
    display: flex;
    justify-content: flex-end;
    gap: 12px;
  }
  
  .cancel-btn,
  .confirm-btn {
    padding: 6px 20px;
    border: none;
    border-radius: 5px;
    font-weight: 500;
    cursor: pointer;
  }
  
  .cancel-btn {
    background-color: #e0e0e0;
    color: #333;
  }
  
  .confirm-btn {
    background-color: #FFC42B;
    color: white;
  }
  