@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

.sidebar__top {
  width: 100%;
}

.sidebar__top h2 {
  display: flex;
  align-items: center;
  column-gap: 0.5rem;
  color: var(--heading-color);
}

.sidebar__top h2 span {
  width: 25px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  font-family: "Poppins", sans-serif;
}
.sidebar__top i {
  font-weight: 400 !important;
  padding: 5px;
  font-size: 1rem;
}

.nav__list {
  display: flex;
  flex-direction: column;
  color: #202224;
  font-size: 20px;
  font-weight: 600;
  font-family: "Poppins", sans-serif;
}
.side{
  padding: 10px;
  cursor: pointer;
  text-decoration: none;
  color:#1d1e1f !important;
  font-family: "Poppins", sans-serif;
  
}

.side1{
  padding: 7px;
  cursor: pointer;
  font-size: 14px;
  text-decoration: none;
  color:#A098AE !important;
  font-family: "Poppins", sans-serif;
}
.iconeorder{
  color:#A098AE !important; 
}
.side1:focus {
  color: #ffff !important;
  background-color: #FFC32A;
  padding: 7px;
  width: 130px;
  border-radius: 10px;
  font-family: "Poppins", sans-serif;
}

.navbar {
  position: fixed;
  top: 50px;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 240px;
  min-height: calc(100vh - 50px);
  background-color: #2B2B2B;
  transform: translateX(0);
  transition: transform 0.3s;
  z-index: 1;
  font-family: "Poppins", sans-serif;
}

@media only screen and (max-width: 1025px) {
  .navbar {
    transform: translateX(-100%);
  }
  .navbar.open {
    transform: translateX(0);
  }
}

@media (max-width: 768px) {
  .navbar {
    width: 250px;
    height: calc(100vh - 60px);
    transform: translateX(-100%);
  }
  .navbar.open {
    transform: translateX(0);
  }
}

.navbar.open {
  transform: translateX(0);
}

.navt-sidetext {
  color:#504d54;
  padding: 6px;
  font-size: 18px;
   text-decoration: none;
} 
.navt-sidetext:hover{
  color: #504d54;
}
.top-nav {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #ffffff;
  padding:15px 18px;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
}
.mobile-nav-btn {
  display: none;
  width: 30px;
  height: 30px;
  padding: 0px !important;
}

@media only screen and (max-width: 1025px) {
  .mobile-nav-btn {
    display: block;
  }
}
.top-logo {
  width: 230px;
  height: auto;
  margin-top: 5px;
}
@media only screen and (max-width: 768px) {
  .navt-sidetext {  
    font-size: 15px;
  } 
}

.profile {
  display: flex;
  align-items: center;
}

.profile img {
  width: 45px;
  height: 45px;
  border: solid 2px #fdfdfd;
  border-radius: 50%;
  object-fit: cover;
}
.settbottom {
  position: relative;
  bottom: 50px;
}
@media only screen and (max-width: 768px) {
  .settbottom {
    position: relative;
    bottom: 100px;
  }
}
.logout-button {
  background: none;
  border: none;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;  
}
.logoutimg{
  width: 40px;
  height: auto;
  margin-right: 6px;
}
.managemenchevront{
  font-size: 14px;
  margin-left: 10px;
  font-weight: 800;
}
.navt-sidetext.active {
  border-radius: 8px;
  text-decoration: none;
  background-color: #FFC32A;
  padding: 6px;
  font-family: "Poppins", sans-serif;

}
.child-txt-nave{
  color: #1d1e1f;
  font-size: 15px;
  text-decoration: none;
  font-weight: 400;
  font-family: "Poppins", sans-serif;
}

.p-3 {
  padding: 0rem 1rem 1rem 1rem !important;
}