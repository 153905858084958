.drafthistory {
    margin-top: 70px;
    padding: 10px;
    padding-bottom: 50px;
  }

 .usertitle{
    font-family: "Nunito Sans", sans-serif;
    font-size: 27px;
    font-weight: 700;

 }


 .tbody-responsive-container{
  height: calc(100% - 0px);
  background-color: #ffff !important;
  overflow-y: auto;
 }
 .common-overall-container {
  background-color: #ffff;
  border-radius: 10px;
  box-shadow: rgba(154, 154, 212, 0.25) 0px 2px 5px -1px, rgba(92, 88, 88, 0.3) 0px 1px 3px -1px;
  overflow: auto;
  max-height: calc(100vh - 194px);
  margin-bottom: 5px;
  position: relative;
}

.table {
  font-size: 16px;
  border-collapse: collapse;
  width: 100%;
}
  tr ,th,td{
   border: none !important;
 }

 .table td{
   text-align: center;
   vertical-align: middle;
   white-space: nowrap; 
   color: #303030 !important;
   font-size: 16px;
   font-weight: 500 !important;
 
 }
 .table thead {
  position: sticky;
  top: 0;
  z-index: 10; /* Increase to stay on top */
  background-color: #FFC42B;
}

.table th {
  background-color: #FFC42B !important;
  color: #303030c9 !important;
  padding: 12px !important;
  font-size: 15px;
  text-align: center;
  white-space: nowrap;
  position: sticky;
  top: 0;
  z-index: 10;
}
.tbody-responsive tr {
 width: 100%;
 table-layout: fixed;
}








